// chatbot.js
export const MainDomain = "https://chata.superlab.ai";


export const sendMessage = async (message, sessionId) => {
    const userId = localStorage.getItem('user_id');
    //const authToken = getAuthToken();
    const authToken = localStorage.getItem('jwt');
    console.log("token: ",authToken)
    const cliente = 'chatbot';  // Define la fuente del mensaje

    try {
        const response = await fetch(`${MainDomain}/chatbot/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authToken
            },
            body: JSON.stringify({
                question: message.trim(),
                user_id: userId,
                session_id: sessionId,
                cliente: cliente
            })
        });

        if (!response.ok) {
            throw new Error('Failed to fetch');
        }

        const contentType = response.headers.get('Content-Type');
        let data;
        
        if (contentType && contentType.startsWith('application/json')) {
            data = await response.json();

            if (data.imageUrl) {
                return { imageUrl: data.imageUrl };
            } else {
                return { response: data.response };
            }
        } else if (contentType && contentType.startsWith('image/')) {
            data = await response.blob();
            const imageUrl = URL.createObjectURL(data);
            return { imageUrl };
        } else {
            throw new Error('Unexpected content type');
        }
    } catch (error) {
        console.error('Error:', error);
        return { response: 'There was an error processing your request.' };
    }
};


export const sendMacroMessage = async (message, sessionId) => {
    const userId = localStorage.getItem('user_id');
    const authToken = localStorage.getItem('jwt');
    const cliente = 'macro';  // Define la fuente del mensaje

    try {
        const response = await fetch(`${MainDomain}/chatbot/macro`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authToken
            },
            body: JSON.stringify({
                question: message.trim(),
                user_id: userId,
                session_id: sessionId,
                cliente: cliente
            })
        });

        if (!response.ok) {
            throw new Error('Failed to fetch');
        }

        const contentType = response.headers.get('Content-Type');
        let data;
        
        if (contentType && contentType.startsWith('application/json')) {
            data = await response.json();

            if (data.imageUrl) {
                return { imageUrl: data.imageUrl };
            } else {
                return { response: data.response };
            }
        } else if (contentType && contentType.startsWith('image/')) {
            data = await response.blob();
            const imageUrl = URL.createObjectURL(data);
            return { imageUrl };
        } else {
            throw new Error('Unexpected content type');
        }
    } catch (error) {
        console.error('Error:', error);
        return { response: 'There was an error processing your request.' };
    }
};

export const copyToClipboard = (text, buttonElement) => {
    navigator.clipboard.writeText(text)
        .then(() => {
            // Añade la clase 'copied' al botón para mostrar el tick
            buttonElement.classList.add('copied');

            // Remueve la clase después de unos segundos
            setTimeout(() => {
                buttonElement.classList.remove('copied');
            }, 2000); // Cambia el tiempo si quieres que el tick se muestre por más o menos tiempo
        })
        .catch(err => {
            console.error("Failed to copy: ", err);
        });
};


export const handleSendMessage = async (message, sessionId, displayMessage, sendMessage) => {
    if (message.trim() === '') return;

    displayMessage({ text: message }, 'user');
    console.log("mandar mensaje a session id: ", sessionId);
    const response = await sendMessage(message, sessionId);

    if (response.imageUrl) {
        displayMessage({ imageUrl: response.imageUrl }, 'bot');
    } else {
        displayMessage({ text: response.response }, 'bot');
    }
};



export async function downloadHistory(userId, sessionId) {    

    console.log(`Downloading history for user: ${userId}, session: ${sessionId}`); // Línea de depuración

    try {
        const response = await fetch(`${MainDomain}/chatbot/download_history/${userId}/${sessionId}`);
        if (response.ok) {
            // Crear un blob a partir de la respuesta
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            console.log(`Blob URL: ${url}`); // Agrega un log para verificar la URL del blob

            // Crear un enlace temporal para descargar el archivo
            const a = document.createElement('a');
            a.href = url;
            a.download = `history_${userId}.txt`;
            document.body.appendChild(a);
            a.click();
            a.remove();

            // Revocar la URL del blob después de su uso
            window.URL.revokeObjectURL(url);
        } else {
            alert('Error al descargar el historial');
        }
    } catch (error) {
        console.error('Error:', error);
        alert('Error al descargar el historial');
    }
}
