// history.js
//import MainDomain  from "../enviroment.js";

export const MainDomain = "https://chata.superlab.ai";
//http://127.0.0.1:8000
export const fetchConversations = async (userId, cliente) => {
    try {
        const response = await fetch(`${MainDomain}/chatbot/get_conversations?user_id=${userId}&cliente=${encodeURIComponent(cliente)}`);
        if (!response.ok) {
            throw new Error('Error fetching conversations');
        }
        const data = await response.json();
        
        return data.conversations; // Asegúrate de que este es el formato correcto
    } catch (error) {
        console.error('Error fetching conversations:', error);
        return {};
    }
};

export const fetchMessagesBySession = async (userId, sessionId, cliente) => {
    try {
        const conversations = await fetchConversations(userId, cliente);
        console.log(conversations[sessionId]);
        return conversations[sessionId] || []; // Devuelve los mensajes de la sesión específica
    } catch (error) {
        console.error('Error fetching messages for session:', error);
        return [];
    }
};


export const updateSessionName = async (userId, cliente, sessionId, newSessionName) => {
    try {
        const response = await fetch(`MainDomain/chatbot/update_session_name`, {
            method: 'PUT', // Asegúrate de usar PUT aquí
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: userId, // Asegúrate de que los nombres de las propiedades coincidan
                cliente,
                session_id: sessionId,
                new_session_name: newSessionName,
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to update session name: ${errorData.detail || response.statusText}`);
        }
        return response.json();
    } catch (error) {
        console.error('Error updating session name:', error);
        throw error;
    }
};

