import React from "react";
export const LogoSuper = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="34px"
    height="32px"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="-0.5 -0.5 96 90"
  >
    <g>
      <path
        style={{ opacity: 1 }}
        fill="#000000"
        d="M -0.5,-0.5 C 31.5,-0.5 63.5,-0.5 95.5,-0.5C 95.5,29.5 95.5,59.5 95.5,89.5C 63.5,89.5 31.5,89.5 -0.5,89.5C -0.5,59.5 -0.5,29.5 -0.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1 }}
        fill="#e9e9e9"
        d="M 43.5,26.5 C 51.1667,26.5 58.8333,26.5 66.5,26.5C 66.5,27.8333 66.5,29.1667 66.5,30.5C 58.4931,30.3336 50.4931,30.5003 42.5,31C 41.1154,33.5848 41.7821,35.5848 44.5,37C 51.3658,39.3473 57.5325,42.8473 63,47.5C 67.2939,56.7216 65.7939,64.8883 58.5,72C 56.2827,73.2754 53.9494,74.2754 51.5,75C 43.1733,75.4997 34.84,75.6664 26.5,75.5C 26.5,74.5 26.5,73.5 26.5,72.5C 35.0168,72.8261 43.3501,72.4927 51.5,71.5C 56.6802,70.4882 60.1802,67.4882 62,62.5C 64.066,55.4959 62.2327,49.9959 56.5,46C 50.6808,43.731 45.1808,40.8977 40,37.5C 37.3502,32.562 38.5169,28.8953 43.5,26.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1 }}
        fill="#515151"
        d="M 41.5,25.5 C 41.8417,26.3382 42.5084,26.6716 43.5,26.5C 38.5169,28.8953 37.3502,32.562 40,37.5C 45.1808,40.8977 50.6808,43.731 56.5,46C 62.2327,49.9959 64.066,55.4959 62,62.5C 60.1802,67.4882 56.6802,70.4882 51.5,71.5C 43.1667,70.8333 34.8333,70.1667 26.5,69.5C 35.1731,69.6664 43.8397,69.4998 52.5,69C 61.7608,63.1085 62.7608,56.1085 55.5,48C 49.3147,45.9356 43.4814,43.1023 38,39.5C 36.5167,36.2976 36.1834,32.9643 37,29.5C 37.7475,27.249 39.2475,25.9156 41.5,25.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1 }}
        fill="#555555"
        d="M 41.5,19.5 C 49.8333,20.1667 58.1667,20.8333 66.5,21.5C 57.4938,21.3336 48.4938,21.5002 39.5,22C 31.6372,27.7083 30.4706,34.5417 36,42.5C 41.4368,44.9859 46.9368,47.4859 52.5,50C 56.2765,52.9547 57.4432,56.788 56,61.5C 54.9513,63.3844 53.4513,64.7177 51.5,65.5C 51.1583,64.6618 50.4916,64.3284 49.5,64.5C 54.1153,61.9351 55.282,58.2684 53,53.5C 48.6369,50.3751 43.8035,48.2085 38.5,47C 30.4431,41.7723 28.2765,34.6057 32,25.5C 34.2975,22.0201 37.4642,20.0201 41.5,19.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1 }}
        fill="#e6e6e6"
        d="M 66.5,21.5 C 66.5,22.5 66.5,23.5 66.5,24.5C 57.9832,24.1739 49.6499,24.5073 41.5,25.5C 39.2475,25.9156 37.7475,27.249 37,29.5C 36.1834,32.9643 36.5167,36.2976 38,39.5C 43.4814,43.1023 49.3147,45.9356 55.5,48C 62.7608,56.1085 61.7608,63.1085 52.5,69C 43.8397,69.4998 35.1731,69.6664 26.5,69.5C 26.5,68.5 26.5,67.5 26.5,66.5C 35.0168,66.8261 43.3501,66.4927 51.5,65.5C 53.4513,64.7177 54.9513,63.3844 56,61.5C 57.4432,56.788 56.2765,52.9547 52.5,50C 46.9368,47.4859 41.4368,44.9859 36,42.5C 30.4706,34.5417 31.6372,27.7083 39.5,22C 48.4938,21.5002 57.4938,21.3336 66.5,21.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 1 }}
        fill="#e8e8e8"
        d="M 41.5,19.5 C 37.4642,20.0201 34.2975,22.0201 32,25.5C 28.2765,34.6057 30.4431,41.7723 38.5,47C 43.8035,48.2085 48.6369,50.3751 53,53.5C 55.282,58.2684 54.1153,61.9351 49.5,64.5C 41.8333,64.5 34.1667,64.5 26.5,64.5C 26.5,63.1667 26.5,61.8333 26.5,60.5C 34.5069,60.6664 42.5069,60.4997 50.5,60C 51.8602,58.0624 51.5268,56.3957 49.5,55C 42.1261,52.5662 35.6261,48.7329 30,43.5C 24.2726,31.1432 27.4392,21.9766 39.5,16C 48.4938,15.5002 57.4938,15.3336 66.5,15.5C 66.5,16.5 66.5,17.5 66.5,18.5C 57.9832,18.1739 49.6499,18.5073 41.5,19.5 Z"
      />
    </g>
  </svg>
);