import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Link } from "@nextui-org/react";
import { LogoSuper } from "./LogoSuper.jsx";

export const MenuSuperior = () => {
  const location = useLocation();
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchPermissions = () => {
      try {
        // Obtener el permiso desde sessionStorage o localStorage
        const storedData = sessionStorage.getItem("permissions") || localStorage.getItem("permissions");
        
        if (storedData) {
          // Convertir el string JSON en objeto
          const parsedData = JSON.parse(storedData);
          setClients(parsedData.clients || []);
          console.log("Permiso obtenido:", parsedData); // Verificar que los datos se obtienen correctamente
        } else {
          console.log("No se encontró permiso en sessionStorage o localStorage.");
        }
      } catch (error) {
        console.error("Error al recuperar permiso:", error);
      }
    };

    fetchPermissions();
  }, [location.pathname]); // Dependencia para que se vuelva a ejecutar si cambia la ubicación

  return (
    <Navbar isBordered position="static" height="4.3rem" className="bg-naranja">
      <NavbarBrand>
        <LogoSuper />  
        <p style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#db043eff' }}>Chata IA</p>
      </NavbarBrand>

      <NavbarContent className="sm:flex gap-4" justify="center">
        {/* Mostrar enlace de navegación Home */}
        <NavbarItem>
          <Link
            href="/"
            color={location.pathname === "/" ? "secondary" : "foreground"}
            style={{ color: location.pathname === "/" ? 'red' : 'inherit' }}
          >
            Home
          </Link>
        </NavbarItem>

        {/* Mostrar pestañas según los permisos */}
        <NavbarItem>
          {(clients.includes("banco_macro") || clients.includes("all")) && (
            <Link
              href="/macro"
              color={location.pathname === "/macro" ? "secondary" : "foreground"}
              style={{ marginRight: '20px', color: location.pathname === "/macro" ? 'red' : 'inherit' }}
            >
              Macro
            </Link>
          )}
          {(clients.includes("banco_ciudad") || clients.includes("all")) && (
            <Link
              href="/bancociudad"
              color={location.pathname === "/bancociudad" ? "secondary" : "foreground"}
              style={{ color: location.pathname === "/bancociudad" ? 'red' : 'inherit' }}
            >
              Banco Ciudad
            </Link>
          )}
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};