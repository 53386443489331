// storage.js
// Variable global para userId
export const generateSessionId = () => {
    const sessionId = 'session_' + Date.now() + '_' + Math.random().toString(36).substr(2, 9);
    localStorage.setItem('session_id', sessionId);
    return sessionId;
};


export const getSessionId = () => localStorage.getItem('session_id');

export const generateUserId = async () => {
    const userId = localStorage.getItem('user_id');
    console.log("generateUser ID = ",userId  )
    return userId;
};

export const getUserId = () => localStorage.getItem('user_id');


export const setAuthToken = (token) => localStorage.setItem('auth_token', token);

export const getAuthToken = () => localStorage.getItem('auth_token');
