import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";

const APP_ID = "chata"; // Define el ID de la aplicación aquí

export default function ProtectedRoute({ children, allowedClients }) {
  const [hasAccess, setHasAccess] = useState(null); // Inicialmente null para manejar la carga
  const token = sessionStorage.getItem("jwt") || localStorage.getItem("jwt");
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      sessionStorage.setItem("redirectPath", location.pathname);
      window.location.href = `http://sso.superlab.ai/login?next=${window.location.origin}/callback&app_id=${APP_ID}`;
      return;
    }
    
    const checkAccess = () => {
      if (location.pathname === "/") {
        // Permitir acceso a la ruta home sin verificar el token
        setHasAccess(true);
        return;
      }

      if (!token) {
        sessionStorage.setItem("redirectPath", location.pathname);
        window.location.href = `http://sso.superlab.ai/login?next=${window.location.origin}/callback&app_id=${APP_ID}`;
        return;
      }

      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decodifica el JWT
        const permissions = decodedToken.permissions || {};
        const clientAccess = allowedClients.some(client => permissions.clients.includes(client) || permissions.clients.includes('all'));

        setHasAccess(clientAccess);
      } catch (error) {
        console.error("Error al verificar el token:", error);
        setHasAccess(false);
      }
    };

    checkAccess();
  }, [token, location.pathname, allowedClients]);

  if (hasAccess === null) {
    // Mostrar una pantalla de carga o nada mientras se verifica el acceso
    return <div>Loading...</div>;
  }

  if (!hasAccess) {
    return <Navigate to="/" />;
  }

  return children;
}


