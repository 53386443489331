// chatbot macro
import React, { useState, useEffect } from 'react';
import { sendMacroMessage, downloadHistory, copyToClipboard, handleSendMessage } from '../../components/chatbot.js'; // Ruta ajustada
import { getUserId, getSessionId, generateSessionId, generateUserId } from '../../components/storage.js'; // Ruta ajustada
import { fetchConversations, fetchMessagesBySession } from '../../components/history.js'; // Ruta ajustada
import ReactMarkdown from 'react-markdown';
import convertTableTextToHtml from '../../components/tableConverter.js';
import { handleKeyDown,useAutoResizeTextArea} from '../../components/textAreautils.js'; // Ruta ajustada
import { v4 as uuidv4 } from 'uuid';  // Importar uuid
import { fetch } from 'whatwg-fetch';
import { classifyMessage } from '../../components/votemessage.js';



export const ChatbotMacro = () => {
    const [userId, setUserId] = useState(getUserId());
    const [sessionId, setSessionId] = useState(getSessionId());
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [sessions, setSessions] = useState({});
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const cliente = 'macro';

    useEffect(() => {
        if (!userId) {
            generateUserId().then((newUserId) => {
                setUserId(newUserId);
            });
        } else {
            fetchConversations(userId, cliente).then(data => {
                const orderedSessions = organizeSessionsByDate(data);
                setSessions(orderedSessions);
            });
        }

        const newSessionId = generateSessionId();
        setSessionId(newSessionId);
    }, [userId]);

    const organizeSessionsByDate = (sessions) => {
        const today = new Date();
        const oneDay = 24 * 60 * 60 * 1000;
        const sevenDays = 7 * oneDay;
        const thirtyDays = 30 * oneDay;

        const orderedSessions = {
            today: [],
            last7Days: [],
            last30Days: [],
        };

        for (const session in sessions) {
            const lastMessageDate = new Date(sessions[session][0].timestamp);

            const diff = today - lastMessageDate;

            if (diff < oneDay) {
                orderedSessions.today.push(session);
            } else if (diff < sevenDays) {
                orderedSessions.last7Days.push(session);
            } else if (diff < thirtyDays) {
                orderedSessions.last30Days.push(session);
            }
        }

        return orderedSessions;
    };

    const handleSessionSelect = async (sessionId) => {
        setSessionId(sessionId);
        const sessionMessages = await fetchMessagesBySession(userId, sessionId, cliente);
        sessionMessages.reverse();
        
        const formattedMessages = [];
        sessionMessages.forEach((msg) => {
            // Asegúrate de que `msg.question` y `msg.response` sean cadenas
            const questionText = typeof msg.question === 'string' ? msg.question : '';
            const responseText = typeof msg.response === 'string' ? msg.response : '';
    
            formattedMessages.push({
                id: uuidv4(),
                text: questionText,
                sender: 'user'
            });
    
            const isImage = responseText.startsWith('https://storage.googleapis.com/usuarios-datalake/graficos');
            formattedMessages.push({
                id: uuidv4(),
                text: isImage ? null : responseText,
                imageUrl: isImage ? responseText : null,
                sender: 'bot'
            });
        });
    
        // El array ya está en el orden deseado, por lo tanto, no es necesario invertir
        setMessages(formattedMessages);
        
    };

    const displayMessage = (message, sender) => {
        console.log('Displaying message:', message);
    
        // Verificar si 'message' es un objeto que contiene 'text' o 'imageUrl'
        let text = null;
        let imageUrl = null;
    
        if (typeof message === 'object' && message !== null) {
            if ('text' in message) {
                text = message.text;
            }
            if ('imageUrl' in message) {
                imageUrl = message.imageUrl;
            }
        } else if (typeof message === 'string') {
            text = message;
        } else {
            console.error('Expected message to be a string or an object, but got:', message);
            return;
        }
    
        // Si no hay texto y no hay URL de imagen, no hacemos nada
        if (!text && !imageUrl) {
            console.error('Message object is missing both text and imageUrl:', message);
            return;
        }
    
        // Crear el nuevo mensaje basado en los datos disponibles
        setMessages(prevMessages => [
            ...prevMessages,
            { id: uuidv4(), text, imageUrl, sender }
        ]);
    };

    const handleSendMessageWrapper = async () => {
        setIsLoading(true); // Inicia el indicador de carga
        setMessage('');  // Limpiar el campo después de enviar el mensaje
        await handleSendMessage(message, sessionId, displayMessage, sendMacroMessage);
        setIsLoading(false); // Termina el indicador de carga
        
    };

    const handleDownloadHistory = () => {
        downloadHistory(userId, sessionId);
    };

    const createNewConversation = () => {
        // Aquí puedes recargar la página o hacer alguna otra lógica para crear una nueva conversación
        window.location.reload(); // Recarga la página
    };

    const handleClassifyMessage = async (messageId, classification) => {
        await classifyMessage(messageId, classification, userId, sessionId, messages, setMessages);
    };
    // Usa el hook para auto-ajustar el tamaño del textarea
    useAutoResizeTextArea();

    

    return (
        
        <div className="chat-simple-container">
            {/* Contenedor para el botón de alternar, posicionado arriba */}
            <div className="toggle-sidebar-container">
                <button
                    className="toggle-sidebar-button"
                    onClick={() => setSidebarOpen(prev => !prev)}
                    aria-label={sidebarOpen ? 'Cerrar barra lateral' : 'Abrir barra lateral'}
                >
                    ☰
                </button>
                <button
                    className="create-new-conversation-button"
                    onClick={createNewConversation}
                    aria-label="Nueva conversación"
                >
                    <i className="fas fa-comment-dots"></i>
                </button>
            </div>
    
            {/* Contenedor principal para la barra lateral y el área de chat */}
            <div className="chat-content">
                {/* Barra lateral */}
                <div className={`chat-sidebar ${sidebarOpen ? '' : 'closed'}`}>
                    <h3>Sessions</h3>
                    <h4>Today</h4>
                    <ul>
                        {sessions?.today && sessions.today.length > 0 ? (
                            sessions.today.map(session => (
                                <li
                                    key={session}
                                    onClick={() => handleSessionSelect(session)}
                                    style={{ cursor: 'pointer', padding: '5px', border: '1px solid #444' }}
                                >
                                    {session}
                                </li>
                            ))
                        ) : (
                            <li>No sessions available today</li>
                        )}
                    </ul>
                    <h4>Last 7 Days</h4>
                    <ul>
                        {sessions?.last7Days && sessions.last7Days.length > 0 ? (
                            sessions.last7Days.map(session => (
                                <li
                                    key={session}
                                    onClick={() => handleSessionSelect(session)}
                                    style={{ cursor: 'pointer', padding: '5px', border: '1px solid #444' }}
                                >
                                    {session}
                                </li>
                            ))
                        ) : (
                            <li>No sessions available in the last 7 days</li>
                        )}
                    </ul>
                    <h5>Last 30 Days</h5>
                    <ul>
                        {sessions?.last30Days && sessions.last30Days.length > 0 ? (
                            sessions.last30Days.map(session => (
                                <li
                                    key={session}
                                    onClick={() => handleSessionSelect(session)}
                                    style={{ cursor: 'pointer', padding: '5px', border: '1px solid #444' }}
                                >
                                    {session}
                                </li>
                            ))
                        ) : (
                            <li>No sessions available in the last 30 days</li>
                        )}
                    </ul>
                </div>
    
                {/* Área principal de chat */}
                <div className={`chat-main ${sidebarOpen ? 'with-sidebar' : 'no-sidebar'}`}>
                    <div className="chat-simple-window">
                        {messages.map((msg) => (
                            <div key={msg.id} className="message-container">
                                <div className={`message ${msg.sender}`}>
                                    {msg.text ? (
                                        msg.text.includes('|') && msg.text.includes('\n') ? (
                                            <div dangerouslySetInnerHTML={{ __html: convertTableTextToHtml(msg.text) }} />
                                        ) : (
                                            <ReactMarkdown>{msg.text}</ReactMarkdown>
                                        )
                                    ) : (
                                        msg.imageUrl && <img src={msg.imageUrl} alt="bot response" />
                                    )}
                                </div>
                                {msg.sender === 'bot' && msg.text && (
                                    <div className="message-actions">
                                    <button 
                                        className="copy-button"
                                        onClick={(e) => copyToClipboard(msg.text, e.currentTarget)}
                                        aria-label="Copy to clipboard"
                                    >
                                        <i className="fas fa-copy"></i>
                                    </button>
                                    <button 
                                        className={`vote-button upvote ${msg.classification === 'upvote' ? 'active' : ''}`}
                                        onClick={() => handleClassifyMessage(msg.id, 'upvote')}
                                        aria-label="Upvote"
                                    >
                                        <i className="fas fa-thumbs-up"></i>
                                    </button>
                                    <button 
                                        className={`vote-button downvote ${msg.classification === 'downvote' ? 'active' : ''}`}
                                        onClick={() => handleClassifyMessage(msg.id, 'downvote')}
                                        aria-label="Downvote"
                                    >
                                        <i className="fas fa-thumbs-down"></i>
                                    </button>
                                </div>
                                )}
                            </div>
                        ))}
                        {/* Mostrar el indicador de carga */}
                        {isLoading && (
                            <div className="loading-indicator">
                                <i className="fas fa-spinner fa-spin"></i> {/* Icono de spinner */}
                                <span className="thinking-text">Chata está pensando<span className="dots"></span></span>
                            </div>
                        )}
                    </div>
                    <div className="chat-input">
                        <textarea
                            id="user-input-simple"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, handleSendMessageWrapper)}
                            placeholder="Type your message here..."
                            rows="1"
                        />
                        <button 
                            onClick={handleSendMessageWrapper} 
                            className="icon-button"
                            aria-label="Send"
                        >
                            <i className="fas fa-paper-plane"></i>
                        </button>

                        <button 
                            onClick={handleDownloadHistory} 
                            className="icon-button"
                            aria-label="Download History"
                            title="Descargar historial"
                        >
                            <i className="fas fa-download"></i>
                        </button>
                    </div>
                    <div className="footer-message">
                        ChaTa puede cometer errores. Comprueba la información importante.
                    </div>
                </div>
            </div>
        </div>
    );
};