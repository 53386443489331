import React, { useState } from 'react';

// textAreautils.js
export function handleKeyDown(e, sendMessageCallback) {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // Permite el salto de línea con Shift + Enter
        return; // No se evita la acción predeterminada
      } else {
        // Envía el mensaje con solo Enter
        e.preventDefault(); // Evita el salto de línea predeterminado
        sendMessageCallback();
      }
    }
  }
  
  export function adjustHeight() {
    const textarea = document.getElementById('user-input-simple');
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
  
  export function useAutoResizeTextArea() {
    React.useEffect(() => {
      const textarea = document.getElementById('user-input-simple');
      textarea.addEventListener('input', adjustHeight);
  
      return () => {
        textarea.removeEventListener('input', adjustHeight);
      };
    }, []);
  }


  