import React from "react";
import { Routes, Route } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { Home } from "./paginas/home/Home";
import { ChatbotMacro } from "./paginas/macro/chatbot_macro";
import { Chatbot } from "./paginas/bancoCiudad/chatbot_bancoCiudad";
import ProtectedRoute from "./components/ProtectedRoute";
import Callback from "./components/Callback";
import { MenuSuperior } from "./components/MenuSuperior";

export default function App() {
  return (
    <NextUIProvider>
      <NextThemesProvider attribute="class" defaultTheme="dark">
        <MenuSuperior />
        <Routes>
          <Route path="/" element={<ProtectedRoute allowedClients={[]}><Home /></ProtectedRoute>} />
          <Route path="/macro" element={<ProtectedRoute allowedClients={['banco_macro']}><ChatbotMacro /></ProtectedRoute>} />
          <Route path="/bancociudad" element={<ProtectedRoute allowedClients={['banco_ciudad']}><Chatbot /></ProtectedRoute>} />
          <Route path="/callback" element={<Callback />} />
        </Routes>
      </NextThemesProvider>
    </NextUIProvider>
  );
}