import React from "react";
import "../../estilos/base.css"; // Importa la hoja de estilos

export const Home = () => {
  return (
    <div className="home-container">
      <div className="home-content">
        <h1>Bienvenido a Super</h1>
        <p>
          En Super, nos dedicamos a desarrollar soluciones innovadoras para el análisis de contenido en redes sociales.
        </p>
        <p>
          Version Beta de Chata.
        </p>
        <p>
        Un chatbot que, al estar conectado a nuestra data lake, hace uso de IA para permitir a los usuarios realizar preguntas sobre los resultados de sus campañas para obtener una respuesta precisa e inmediatamente. Nos permite un acceso ágil y automatizado a la información, además de que facilita la obtención de insights.
        </p>
      </div>
    </div>
  );
};
