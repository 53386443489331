// Función para convertir el texto de la tabla en HTML
const convertTableTextToHtml = (tableText) => {
    // Limpiar el texto eliminando comillas triples al final y caracteres no deseados
    let cleanedText = tableText.trim().replace(/```$/, '');
    
    // Eliminar líneas que no corresponden a datos de tabla (como líneas con solo `---`)
    const lines = cleanedText.split('\n');
    const headerLineIndex = lines.findIndex(line => line.includes('|'));
    if (headerLineIndex === -1) return ''; // Si no hay línea de encabezado, retornar vacío
    
    // Extraer las cabeceras
    const headers = lines[headerLineIndex].split('|').map(header => header.trim());
    
    // Extraer las filas de datos, excluyendo líneas de encabezado y de separación
    const rows = lines.slice(headerLineIndex + 1)
        .filter(line => line.trim() !== '' && !line.includes('---'))
        .map(line => line.split('|').map(cell => cell.trim()));
    
    // Construir el HTML de la tabla
    let tableHtml = '<table style="width: 100%; border-collapse: collapse;">';
    tableHtml += '<thead><tr>';
    headers.forEach(header => {
        tableHtml += `<th style="padding: 12px; border-bottom: 2px solid #444; background-color: #1e1e1e; color: #f9f9f9;">${header}</th>`;
    });
    tableHtml += '</tr></thead><tbody>';
    rows.forEach(row => {
        tableHtml += '<tr>';
        row.forEach(cell => {
            tableHtml += `<td style="padding: 12px; border-bottom: 1px solid #333; background-color: #2c2f33; color: #ddd;">${cell}</td>`;
        });
        tableHtml += '</tr>';
    });
    tableHtml += '</tbody></table>';
    
    return tableHtml;
};

// Exportar la función
export default convertTableTextToHtml;
